import * as React from "react";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

import pallets from "../../../assets/pallets.jpg";
import bananas from "../../../assets/bananas.jpg";

export default function Hero() {
  const { t } = useTranslation();
  const isMobileScreen = useMediaQuery("(max-width:700px)");
  const [currentBackground, setCurrentBackground] = React.useState(pallets);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBackground((prev) => (prev === pallets ? bananas : pallets));
    }, 5000); // Switch every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        minHeight: { xs: "70vh", md: "95vh" },
        backgroundImage: `url(${currentBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "4rem",
        transition: "background-image 1s ease-in-out", // Smooth transition between images
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            alignSelf: "center",
            backgroundColor: "rgba(42,63,63,0.82)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "2rem",
            outline: "1px solid",
            width: { xs: "98%", md: "1000px", xl: "1500px" },
            minHeight: { xs: "390px", md: "500px" },
            outlineColor:
              theme.palette.mode === "light"
                ? alpha("#BFCCD9", 0.5)
                : alpha("#445f64", 0.1),
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 12px 8px ${alpha("#445f64", 0.2)}`
                : `0 0 24px 12px ${alpha("rgba(26,73,73,0.99)", 0.2)}`,
            p: { xs: '10px 1rem', md: 2, xl: 3 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          })}
        >
          <img
            src="https://storage.googleapis.com/pifa.se/partihandel.se_row_inverted.png"
            width={isMobileScreen ? "350px" : "600px"}
            alt="logo of sitemark"
          />
          <Stack
            spacing={2}
            useFlexGap
            sx={{
              width: { xs: "100%", sm: "90%", md: "90%" },
              minHeight: { xs: "0", md: "20vh" },
            }}
          >
            <Typography
              component="h1"
              variant="h1"
              sx={(theme) => ({
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignSelf: "center",
                textAlign: "center",
                color: "white",
                fontSize: { xs: "3.2rem", md: "7rem" },
              })}
            >
              {t("startpage.herotitle")}
            </Typography>
            <Typography
              variant="body1"
              textAlign="center"
              color="text.primary"
              sx={{ fontSize: { xs: "1.6rem", md: "3rem" }, color: "white" }}
            >
              {t("startpage.herosubtitle")}
            </Typography>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
