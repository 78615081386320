const moment = require("moment-timezone");

export const getDateDescriptionAndTime = (inputDate) => {
  // Get current date
  const currentDate = new Date();

  // Get the hours and minutes of the input date
  const inputDateHours = inputDate.getHours();
  const inputDateMinutes = inputDate.getMinutes();

  // Format the time properly
  const inputDateTime =
    inputDateHours +
    ":" +
    (inputDateMinutes < 10 ? "0" : "") +
    inputDateMinutes;

  // Set the time of the current date to midnight
  currentDate.setHours(0, 0, 0, 0);

  // Set the time of the input date to midnight
  inputDate.setHours(0, 0, 0, 0);

  // Calculate the difference in milliseconds between the two dates
  const timeDifference = inputDate - currentDate;

  // Calculate the number of milliseconds in a day
  const oneDay = 24 * 60 * 60 * 1000;

  // Get the current year and the year of the input date
  const currentYear = currentDate.getFullYear();
  const inputYear = inputDate.getFullYear();

  // Check if the input date is in the past
  if (timeDifference < 0) {
    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  // Check if the input date is today, tomorrow, or within the next 6 days
  if (timeDifference >= 0 && timeDifference < oneDay) {
    return "Idag kl " + inputDateTime;
  } else if (timeDifference >= oneDay && timeDifference < oneDay * 2) {
    return "Imorgon kl " + inputDateTime;
  } else if (timeDifference >= oneDay * 2 && timeDifference < oneDay * 7) {
    // Return the name of the weekday for the next 6 days
    const weekdays = [
      "Söndag",
      "Måndag",
      "Tisdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lördag",
    ];
    const dayIndex =
      (currentDate.getDay() + Math.floor(timeDifference / oneDay)) % 7;
    return weekdays[dayIndex] + " kl " + inputDateTime;
  } else {
    // Check if the input date is in the current year
    const options =
      inputYear === currentYear
        ? { year: "numeric", month: "short", day: "numeric" }
        : {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          };

    return (
      inputDate.toLocaleDateString("sv-SE", options) + " kl " + inputDateTime
    );
  }
};

export function getDaysBack(days) {
  // Create a new date object to avoid modifying the current date
  const today = new Date();

  // Create a new date for the past date to avoid mutation
  const pastDate = new Date(today);
  pastDate.setDate(today.getDate() - days);

  return {
    pastDate: pastDate,
  };
}

export const getLocalDateTime = (item, mode) => {
  if (item !== null && item !== undefined) {
    if (typeof item === "string") {
      if (mode === "date") {
        return item.slice(0, 10);
      } else if (mode === "dateandtime") {
        return item.slice(0, 10) + " " + item.slice(11, 16);
      } else if (mode === "dateandtimeandseconds") {
        return item.slice(0, 10) + " " + item.slice(11, 19);
      }
    } else if (typeof item === "object") {
      if (mode === "date") {
        return item.toISOString().slice(0, 10);
      } else if (mode === "dateandtime") {
        return (
          item.toISOString().slice(0, 10) +
          " " +
          item.toISOString().slice(11, 16)
        );
      } else if (mode === "dateandtimeandseconds") {
        return (
          item.toISOString().slice(0, 10) +
          " " +
          item.toISOString().slice(11, 19)
        );
      }
    }
  } else {
    return "";
  }
};

export const getLocalDateTimeShort = (item, mode) => {
  if (item !== null && item !== undefined) {
    let localDate;

    if (typeof item === "string") {
      localDate = new Date(item);
    } else if (typeof item === "object") {
      localDate = new Date(item.toISOString());
    }

    // Adjust to GMT+1
    localDate.setHours(localDate.getHours() + 1);

    if (mode === "date") {
      return localDate.toISOString().slice(5, 10);
    } else if (mode === "dateandtime") {
      return (
        localDate.toISOString().slice(5, 10) +
        " " +
        localDate.toISOString().slice(11, 16)
      );
    }
  } else {
    return "";
  }
};

export const isDateGreaterThanToday = (targetDate) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  targetDate.setHours(0, 0, 0, 0);

  return targetDate.getTime() > today.getTime();
};
